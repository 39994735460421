import React, {useEffect, useState} from "react";
import {
	campaignHasPGType,
	currencySymbol, getClosestTo15Date,
	getTimeZone, isDigit, parseAPIDateTimeWithTimeZone
} from "../../../../libs/common_utils";
import {useForm} from "../../../../libs/component_utils";
import {
	Divider,
	Dropdown,
	Input,
	Radio,
	Select,
	Form,
	Segment,
	Popup,
	Icon,
	Checkbox,
	Message, Confirm
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import StrategyManageContext from "../context";
import PropTypes from "prop-types";
import {
	daily_pacing_intervals,
	daily_pacing_options,
	frequency_intervals,
	frequency_options, goal_media_options,
	goal_options, media_type_options
} from "../../fixtures";
import parseISO from "date-fns/parseISO";
import DraftModel from "../../../../models/draft_strategy";
import Frequency from "../../../../models/object_value/frequency";
import Pacing from "../../../../models/object_value/pacing";
import Goal from "../../../../models/object_value/goal";
import add from "date-fns/add";
import isDate from "date-fns/isDate";
import {Types as frequencyTypes, Intervals as frequencyIntervals} from "../../../../models/enum/frequency";
import {Config} from "../../../../config/api";
import {isBefore, isPast} from "date-fns";
import {freq_optimization_enabled_by_goal_type} from "../../../../models/campaign";
import {NavigationButtonDiv} from "./navigation_buttons";
import {Steps} from "../../../../models/enum/strategy";
import StrategiesService from "../../../../services/strategy";
import {useFlags} from "launchdarkly-react-client-sdk";
import { useIntl } from "react-intl";
import DecimalInput from "../../../common/decimal_input";

export const MarketingObjectiveStep = () => {
	const context = React.useContext(StrategyManageContext);
	const intl = useIntl();
	const service = context.services.current.get("strategies");
	const strategy_id = context.strategy_id;
	const is_t1_edit = (service instanceof StrategiesService);
	const {whitelabelChannelDisplay, whitelabelChannelVideo} = useFlags();

	const [loading, setLoading] = useState(true);
	let _isMounted = React.useRef(true);
	let use_start_cannot_be_ticket = React.useRef(false);
	let use_end_cannot_be_ticket = React.useRef(false);

	const [formSending, setFormSending] = useState(false);
	const [serverError, setServerError] = useState("");

	const { campaign } = context;
	const minTimeInSeconds = Math.min(...campaign.budget_flights.map(f => f.start_date));
	const maxTimeInSeconds = Math.max(...campaign.budget_flights.map(f => f.end_date));
	const minTimeInSecondsTz = Math.min(...campaign.budget_flights.map(f => f.start_date_tz));
	const maxTimeInSecondsTz = Math.min(...campaign.budget_flights.map(f => f.end_date_tz));
	const campaign_start_date = new Date(minTimeInSeconds);
	const campaign_start_date_tz = new Date(minTimeInSecondsTz);
	const campaign_end_date = new Date(maxTimeInSeconds);
	const campaign_end_date_tz = new Date(maxTimeInSecondsTz);

	const currentDateWithTimeZone = parseAPIDateTimeWithTimeZone(new Date(), context.campaign.time_zone);
	const campaignStarted = isBefore(campaign_start_date_tz, currentDateWithTimeZone);
	const campaignFinished = isBefore(campaign_end_date_tz, currentDateWithTimeZone);

	const min_start_date = campaign_start_date;

	const initial_start_date = add(currentDateWithTimeZone, { days: 1 });
	const initial_end_date = add(initial_start_date, {days: 30});

	const isEdit = (context.strategy_id > 0);

	const [commonData, setCommonData] = useState({
		"goal_types": [],
		"goal_types_loading": false
	});

	let get_continue = React.useRef(true);
	/**
	 * Save local strategy
	 * @param {boolean} is_continue
	 */
	const setOperationType = (is_continue=true) => {
		get_continue.current = is_continue;
	};

	/**
	 * Save local strategy
	 * @param {object} params
	 */
	const addStrategyForm = (params) => {
		(async () => {
			try {
				const strategy = DraftModel.fromJson(params);
				setFormSending(true);

				const response = await service.save(strategy.toJson());
				/** @namespace response.data **/

				if(response.data.id > 0) {
					if(is_t1_edit) {
						response.data["t1_id"] = response.data.id;
					}

					context.updateStrategyData(response.data);
					if(get_continue.current) {
						context.stepNavigation.passMarketingObjective(parseInt(response.data.id, 10));
					} else {
						context.strategySuccessfullyUpdated(response.data.strategy_name);
					}
				}
			} catch(e) {
				setServerError(e.error.message);
			} finally {
				if(_isMounted.current) {
					setFormSending(false);
				}
			}
		})();
	};

	/**
	 * do a custom validation
	 * @param e
	 * @param {object} fields
	 */
	const postValidation = (e, fields) => {
		let errors = {};

		if(fields?.budget && !DraftModel.budgetIsValid(fields.budget)) {
			errors["budget"] = intl.formatMessage({
				id: "ERROR_BUDGET_LT_MIN",
				defaultMessage: "Budget must be a positive number greater than {min}",
			}, {
				min: DraftModel.BUDGET.MIN
			});
		}

		if(fields?.goal_value) {
			const goal = commonData.goal_types.find(x => x.value === fields.goal_type);
			if(!goal) {
				errors["goal_type"] = intl.formatMessage({
					id: "ERROR_GOAL_TYPE_UNKNOWN",
					defaultMessage: "Unknown goal type was chosen",
				});
			} else if(!Goal.isValid(fields.goal_value, goal.minimum, goal.maximum)) {
				errors["goal_value"] = intl.formatMessage({
					id: "ERROR_GOAL_VALUE_OUTSIDE_MIN_MAX",
					defaultMessage: "Goal value must be a positive number between {min} and {max}",
				}, {
					min: goal.minimum,
					max: goal.maximum,
				});
			}
		}

		if(fields?.pacing_amount && !Pacing.isValid(fields.pacing_amount)) {
			errors["pacing_amount"] = intl.formatMessage({
				id: "ERROR_PACING_OUTSIDE_MIN_MAX",
				defaultMessage: "Pacing amount must be a positive number between {min} and {max}",
			}, {
				min: Pacing.MIN,
				max: Pacing.MAX,
			});
		}

		if(fields?.frequency_amount && !Frequency.isValid(fields.frequency_amount)) {
			errors["frequency_amount"] = intl.formatMessage({
				id: "ERROR_FREQUENCY_AMOUNT_OUTSIDE_MIN_MAX",
				defaultMessage: "Frequency amount must be a positive number between {min} and {max}",
			}, {
				min: Frequency.MIN,
				max: Frequency.MAX,
			});
		}

		if(!fields.use_campaign_start && !isDate(fields.start_date)) {
			errors["start_date"] = intl.formatMessage({
				id: "ERROR_START_DATE_REQUIRED",
				defaultMessage: "Start date is a required field",
			});
		}

		if(!fields.use_campaign_end && !isDate(fields.end_date)) {
			errors["end_date"] = intl.formatMessage({
				id: "ERROR_END_DATE_REQUIRED",
				defaultMessage: "End date is a required field",
			});
		}

		if (fields?.min_bid && fields?.max_bid) {
			if (parseFloat(fields.min_bid) > parseFloat(fields.max_bid)) {
				errors["max_bid"] = intl.formatMessage({
					id: "ERROR_MIN_BID_GT_MAX_BID",
					defaultMessage: "Max Bid must be greater than or equal Min Bid",
				});
			}
		}

		return errors;
	};

	/**
	 * Decide which type we should set by default
	 * @param {boolean} displayLaunchDarklyFlag
	 * @param {string} mediaType
	 * @returns {string}
	 */
	const decideWhichMediaTypeToUseByDefault = (displayLaunchDarklyFlag, mediaType) => {
		if(mediaType) {
			return mediaType;
		}

		return (displayLaunchDarklyFlag)? "display" : "video";
	}

	const {values, errors, onChange, onSwitch, loadData, onSubmit, updateValues} = useForm(addStrategyForm, {
		"status": true,
		"campaign_id": context.campaign.id,
		"strategy_name": "",
		"media_type": decideWhichMediaTypeToUseByDefault(whitelabelChannelDisplay, context.strategy["media_type"]),
		"use_campaign_start": false,
		"start_date": initial_start_date,
		"use_campaign_end": false,
		"end_date": initial_end_date,
		"budget": "",
		"goal_type": context.campaign.is_pg? "spend" : context.campaign.goal_type,
		"goal_value": "",
		"min_bid": "",
		"max_bid": "",
		"bid_aggressiveness": 50,
		"bid_price_is_media_only": true,
		"pacing_type": "asap",
		"pacing_interval": "hour",
		"pacing_amount": "",
		"frequency_optimization": true,
		"frequency_type": frequencyTypes.ASAP,
		"frequency_amount": "",
		"frequency_interval": frequencyIntervals.HOUR
	}, postValidation);

	// effect, to control correct state of frequency optimized
	useEffect(() => {
		if(!freq_optimization_enabled_by_goal_type(context.campaign.goal_type) && Boolean(values.frequency_optimization)) {
			loadData({
				...values,
				"frequency_optimization": 0
			});
		}
	}, [context.campaign.goal_type]);


	/**
	 * load goal types based on campaign type
	 * @param {string} campaign_type
	 * @param {string} media_type
	 * @return {Promise}
	 */
	const loadGoalTypes = (campaign_type, media_type) => {
		setCommonData({
			...commonData,
			"goal_types_loading": true
		});

		return new Promise((resolve, reject) => {
			context.services.current.get("commonService").getGoalTypes(campaign_type, media_type).then(r => {
				if(campaignHasPGType(context.campaign) && !r.data.find(x => x.value === "spend")) {
					r.data.push({"id": 6, "is_monetary": 1, "maximum": 9999999.9999, "minimum": 0.01, "status": 1, "title": "CPM Spend", "value": "spend"});
				}

				// in case we cannot find selected value for new set of goals let's apply 1st possible value
				if(!r.data.find(x => x.value === values.goal_type)) {
					onChange(null, {"name": "goal_type", "value": r.data[0].value});
				}

				setCommonData({
					...commonData,
					"goal_types": r.data,
					"goal_types_loading": false
				});

				setTimeout(resolve, 30);
			}).catch(e => {
				setCommonData({
					...commonData,
					"goal_types_loading": false
				});
				setTimeout(reject(e), 30);
			});
		})
	};

	// load media type
	useEffect(() => {
		if(loading) {
			return;
		}

		(async() => {
			await loadGoalTypes(context.campaign.goal_type, values.media_type)
		})();
	}, [values.media_type]);

	// load data when necessary
	React.useEffect(() => {
		let promises = [];
		promises.push(loadGoalTypes(context.campaign.goal_type, values.media_type));
		if(isEdit) {
			(async () => {
				try {
					const strategy = DraftModel.fromJson(context.strategy);
					let json = strategy.toJson();

					if(!is_t1_edit) {
						json.start_date = json.start_date? getClosestTo15Date(new Date(json.start_date)) : json.use_campaign_start? campaign_start_date : initial_start_date;
						json.end_date = json.end_date? getClosestTo15Date(new Date(json.end_date)) : json.use_campaign_end? campaign_end_date : initial_end_date;
					} else {
						json.start_date = json.start_date? new Date(json.start_date) : json.use_campaign_start? campaign_start_date : initial_start_date;
						json.end_date = json.end_date? new Date(json.end_date) : json.use_campaign_end? campaign_end_date : initial_end_date;
					}
					use_start_cannot_be_ticket.current = campaignStartCannotBeTicked(campaignStarted, Boolean(json.use_campaign_start), json.start_date);
					use_end_cannot_be_ticket.current = campaignEndCannotBeTicked(campaignFinished, Boolean(json.use_campaign_end));

					loadData(Object.assign(values, json));
				} catch (e) {
					console.warn(e);
				}
			})();
		} else {
			use_start_cannot_be_ticket.current = campaignStartCannotBeTicked(campaignStarted, true, values.start_date);
			use_end_cannot_be_ticket.current = campaignEndCannotBeTicked(campaignFinished, true);
		}

		// handle all promises
		Promise.all(promises).catch(e => {
			setServerError(e.error.message);
		}).finally(() => {
			setLoading(false);
		});

		return () => {
			_isMounted.current = false;
		}
	}, [strategy_id]);

	React.useLayoutEffect(() => {
		function preventInput(e) {
			if(e.target.validity && e.target.pattern) {
				if (e.target.value.length >= e.target.oldValue.length) {
					let reg = new RegExp(e.target.pattern);
					if (!reg.test(e.target.value)) {
						e.target.value = e.target.oldValue;
						return false;
					}
				}
			}

			e.target.oldValue = e.target.value;
		}

		document.querySelectorAll("input[type='text'][pattern]").forEach(n => {
			n.oldValue = n.value;
			n.addEventListener("input", preventInput);
		});

		return () => {
			document.querySelectorAll("input[type='text'][pattern]").forEach(n => {
				n.removeEventListener("input", preventInput, true);
			});
		}
	}, []);

	/**
	 * handle start date change
	 * @param {Date} date
	 */
	const handleStartDateChange = date => {
		onChange(null, {"name": "start_date", "value": date});
	};

	/**
	 * handle end date change
	 * @param date
	 */
	const handleEndDateChange = date => {
		onChange(null, {"name": "end_date", "value": date});
	};

	/**
	 * handle custom media channel change
	 * @param {object} value
	 */
	const handleMediaChannelChange = value => {
		updateValues(value);
	};

	/**
	 * User cannot tick 'use campaign start date' when it started and not ticked yet
	 * @param {boolean} started
	 * @param {boolean} use_start_ticket
	 * @param {Date} start_date
	 * @return {boolean}
	 */
	const campaignStartCannotBeTicked = (started, use_start_ticket, start_date) => {
		return (!isEdit && started) || (isEdit && started && !use_start_ticket) || (is_t1_edit && isPast(start_date));
	};

	/**
	 * User cannot tick 'use campaign end date' when it finished and not ticked yet
	 * @param {boolean} finished
	 * @param {boolean} use_end_ticket
	 * @return {boolean}
	 */
	const campaignEndCannotBeTicked = (finished, use_end_ticket) => {
		return (!isEdit && finished) || (isEdit && finished && !use_end_ticket);
	};

	/**
	 * Check that start date cannot be changed
	 * @return {boolean}
	 */
	const startDateCannotBeChanged = () => {
		const checkbox_checked = Boolean(values.use_campaign_start);
		if(checkbox_checked) {
			return true;
		}

		return is_t1_edit && isPast(values.start_date);
	};

	if(loading) {
		return (<Segment disabled tertiary textAlign="center" className="loading" style={{"height": "300px"}}>
			&nbsp;
		</Segment>);
	}

	return (
		<>
		<Message
			style={{ "marginTop": "10px" }}
			error
			hidden={!serverError}
			size="tiny"
			content={serverError}
		/>
		<Form method="POST" onSubmit={onSubmit} size="small" loading={formSending || commonData.goal_types_loading} noValidate error={!!Object.keys(errors).length} autoComplete="off">
			<Form.Field inline>
				<label>
					{intl.formatMessage({
						id: "LABEL_STATUS",
						defaultMessage: "Status",
					})}
				</label>
				<Radio
					name="status"
					label={intl.formatMessage({
						id: "STATUS_ACTIVE",
						defaultMessage: "Active",
					})}
					value={1}
					checked={Boolean(values.status)}
					onChange={onChange}
				/>
				<Radio
					style={{ "marginLeft": "15px" }}
					name="status"
					label={intl.formatMessage({
						id: "STATUS_INACTIVE",
						defaultMessage: "Inactive",
					})}
					value={0}
					checked={!Boolean(values.status)}
					onChange={onChange}
				/>
			</Form.Field>
			<Form.Field inline error={errors.hasOwnProperty("strategy_name")} required>
				<label>
					{intl.formatMessage({
						id: "LABEL_STRATEGY_NAME",
						defaultMessage: "Strategy Name",
					})}
				</label>
				<Input
					name="strategy_name"
					required
					minLength="2"
					maxLength="128"
					defaultValue={values.strategy_name}
					onBlur={onChange}
					className="input-field-wider"
				/>
				<div className="custom-error">{errors["strategy_name"]}</div>
			</Form.Field>
			<MediaTypePicker value={values.media_type}
											 onChange={handleMediaChannelChange}
											 flags={{"display": whitelabelChannelDisplay, "video": whitelabelChannelVideo}}
											 is_t1_edit={is_t1_edit}
											 is_edit={isEdit} />
			<Form.Field inline>
				<label>
					{intl.formatMessage({
						id: "LABEL_TIME_ZONE",
						defaultMessage: "Time Zone",
					})}
				</label>
				<div className="ui labeled input">
					{getTimeZone(context.campaign.time_zone, context.timezones)}
				</div>
			</Form.Field>
			<Form.Group inline width="equal">
				<Form.Field required>
					<label>
						{intl.formatMessage({
							id: "LABEL_START_END_DATE",
							defaultMessage: "Start/End Date",
						})}
						{campaignHasPGType(context.campaign) && (
							<Popup
								inverted
								content={intl.formatMessage({
									id: "HINT_START_END_DATE_PG",
									defaultMessage: "Please make sure the dates match the Programmatic Marketplace agreement",
								})}
								size="mini"
								trigger={
									<Icon
										name="help circle"
										style={{ "position": "relative", "left": "3px" }}
										className="cursor-help"
									/>
								}
							/>
						)}
					</label>
				</Form.Field>
				<Form.Field>
					<Checkbox
						name="use_campaign_start"
						label={intl.formatMessage({
							id: "LABEL_USE_CAMPAIGN_START",
							defaultMessage: "Use campaign start",
						})}
						value={1}
						checked={Boolean(values.use_campaign_start)}
						disabled={use_start_cannot_be_ticket.current}
						onClick={(e, input) => !use_start_cannot_be_ticket.current && onSwitch(e, input)}
					/>
				</Form.Field>
				<Form.Field className="date-picker" disabled={startDateCannotBeChanged()} error={errors.hasOwnProperty("start_date")}>
					<DatePicker
						minDate={min_start_date}
						maxDate={values.end_date}
						selected={values.start_date}
						name="start_date"
						onChange={handleStartDateChange}
						showTimeSelect
						timeFormat={Config.timeFormat}
						timeIntervals={15}
						timeCaption="time"
						dateFormat={Config.fullDateTimeFormat}
					/>
					<div className="custom-error" style={{"marginLeft": 0}}>{errors["start_date"]}</div>
				</Form.Field>
				<hr className="date-delimiter" />
				<Form.Field>
					<Checkbox
						name="use_campaign_end"
						label={intl.formatMessage({
							id: "LABEL_USE_CAMPAIGN_END",
							defaultMessage: "Use campaign end",
						})}
						value={1}
						checked={Boolean(values.use_campaign_end)}
						disabled={use_end_cannot_be_ticket.current}
						onClick={(e, input) => !use_end_cannot_be_ticket.current && onSwitch(e, input)}
					/>
				</Form.Field>
				<Form.Field className="date-picker" disabled={Boolean(values.use_campaign_end)} error={errors.hasOwnProperty("end_date")}>
					<DatePicker
						name="end_date"
						minDate={values.start_date}
						selected={values.end_date}
						onChange={handleEndDateChange}
						showTimeSelect
						timeFormat={Config.timeFormat}
						timeIntervals={15}
						timeCaption="time"
						dateFormat={Config.fullDateTimeFormat}
					/>
					<div className="custom-error" style={{"marginLeft": 0}}>{errors["end_date"]}</div>
				</Form.Field>
			</Form.Group>
			{!campaignHasPGType(context.campaign) &&
				<Form.Field inline error={errors.hasOwnProperty("budget")}>
					<label>
						{intl.formatMessage({
							id: "LABEL_BUDGET",
							defaultMessage: "Budget",
						})}{" "}{intl.formatMessage({
							id: "LABEL_OPTIONAL",
							defaultMessage: "(optional)",
						})}
					</label>
					<DecimalInput
						type="number"
						name="budget"
						step={0.01}
						places={2}
						defaultValue={values.budget}
						label={currencySymbol(context.campaign.currency_code, context.currencies)}
						autoComplete="off"
						onBlur={onChange}
					/>
					<div className="custom-error">{errors["budget"]}</div>
				</Form.Field>
			}
			<GoalTypeComponent
				onChange={onChange}
				values={values}
				errors={errors}
				loading={commonData.goal_types_loading}
				isPG={campaignHasPGType(context.campaign)}
				options={commonData.goal_types.map(goal => {
					return {
						"text": goal.title,
						"value": goal.value
					}
				})}
				campaign_goal_values={context.campaign.goal_value}
				currency_code={currencySymbol(context.campaign.currency_code, context.currencies)} />
			{values.goal_type !== "spend" && (
				<Form.Field
					inline
					error={
						errors.hasOwnProperty("min_bid") ||
						errors.hasOwnProperty("max_bid") ||
						errors.hasOwnProperty("bid_aggressiveness")
					}
				>
					<label>
						{intl.formatMessage({
							id: "LABEL_BID",
							defaultMessage: "Bid",
						})}{" "}
						{intl.formatMessage({
							id: "LABEL_OPTIONAL",
							defaultMessage: "(optional)",
						})}
					</label>
					<span style={{ marginInlineEnd: 10 }}>
						{intl.formatMessage({
							id: "LABEL_MIN_BID_CPM",
							defaultMessage: "Min Bid CPM",
						})}
					</span>
					<DecimalInput
						type="number"
						name="min_bid"
						min={0}
						max={9_999_999.99}
						step={0.01}
						places={2}
						defaultValue={values.min_bid}
						label={currencySymbol(
							context.campaign.currency_code,
							context.currencies
						)}
						autoComplete="off"
						onBlur={onChange}
					/>
					<span style={{ marginInlineStart: 20, marginInlineEnd: 10 }}>
						{intl.formatMessage({
							id: "LABEL_MAX_BID_CPM",
							defaultMessage: "Max Bid CPM",
						})}
					</span>
					<DecimalInput
						type="number"
						name="max_bid"
						min={0}
						max={9_999_999.99}
						step={0.01}
						places={2}
						defaultValue={values.max_bid}
						label={currencySymbol(
							context.campaign.currency_code,
							context.currencies
						)}
						autoComplete="off"
						onBlur={onChange}
					/>
					{values.goal_type === "reach" && (
						<>
							<span style={{ marginInlineStart: 20, marginInlineEnd: 10 }}>
								{intl.formatMessage({
									id: "LABEL_BID_AGGRESSIVENESS",
									defaultMessage: "for Total Spend for",
								})}
							</span>
							<Input
								type="number"
								name="bid_aggressiveness"
								required
								min={0}
								max={100}
								defaultValue={values.bid_aggressiveness}
								label="%"
								labelPosition="right"
								autoComplete="off"
								onBlur={onChange}
							/>
						</>
					)}
					<div className="custom-error">
						{errors["min_bid"] || errors["max_bid"] || errors["bid_aggressiveness"]}
					</div>
				</Form.Field>
			)}
			{!campaignHasPGType(context.campaign) &&
				<><Form.Group inline>
					<Form.Field required>
						<label>
							{intl.formatMessage({
								id: "LABEL_DAILY_PACING",
								defaultMessage: "Daily Pacing",
							})}
						</label>
					</Form.Field>
					<Form.Field className="dropdown__125">
						<Select
							fluid
							name="pacing_type"
							options={daily_pacing_options(intl)}
							onChange={onChange}
							value={values.pacing_type}
						/>
					</Form.Field>
					<Form.Field error={errors.hasOwnProperty("pacing_amount")}>
						<DecimalInput
							type="number"
							name="pacing_amount"
							places={2}
							step={0.01}
							onBlur={onChange}
							required
							defaultValue={values.pacing_amount}
							label={currencySymbol(context.campaign.currency_code, context.currencies)}
							autoComplete="off"
						/>
						<div className="custom-error" style={{"marginLeft": "0"}}>{errors["pacing_amount"]}</div>
					</Form.Field>
					<Form.Field className="dropdown__125">
						<Select
							fluid
							name="pacing_interval"
							options={daily_pacing_intervals(intl)}
							onChange={onChange}
							value={values.pacing_interval}
						/>
					</Form.Field>
				</Form.Group>
				<FrequencyCapComponent
					onChange={onChange}
					values={values}
					errors={errors}
					campaign={context.campaign} /></>
			}
			<Divider hidden />
			<Divider hidden />
			<Divider hidden />
			<NavigationButtonDiv
				loading={loading}
				step={Steps.MARKETING_OBJECTIVES}
				isPG={context.campaign.is_pg}
				onBackClick={() => {}}
				onSave={setOperationType}
				is_t1_edit={service instanceof StrategiesService}
				onCancelClick={context.getBack}
			/>
		</Form>
	</>);
};
MarketingObjectiveStep.propTypes = {
};

const MediaTypePicker = React.memo(({value, is_edit, flags, is_t1_edit, onChange}) => {
	const intl = useIntl();
	const [confirmOpened, setConfirmOpened] = useState(false);
	const cachedInput = React.useRef({});
	const alreadyShowed = React.useRef(false);

	// redesign confirmation buttons
	React.useLayoutEffect(() => {
		if(confirmOpened) {
			document.querySelectorAll("#media_channel_confirm button").forEach(n => {
				n.classList.add("mini");
			});
		}
	}, [confirmOpened]);

	/**
	 * handle changing media type
	 * @param e
	 * @param {object} input
	 */
	const handleChangeMedia = (e, input) => {
		const value = {[input.name]: input.value};
		if(!is_edit || alreadyShowed.current) {
			onChange(value);
		} else {
			cachedInput.current = value;
			setConfirmOpened(true);
		}
	}

	/**
	 * handle changing media type after user confirm change
	 */
	const handleChangeMediaTypeConfirmed = () => {
		alreadyShowed.current = true;
		onChange(cachedInput.current);
		setConfirmOpened(false);
	}

	/**
	 * generate form control
	 * @param {boolean} video
	 * @param {boolean} display
	 * @return {JSX.Element}
	 */
	const formControl = ({video, display}) => {
		if(video && display) {
			return <Select
				name="media_type"
				options={media_type_options(intl)}
				onChange={handleChangeMedia}
				value={value} />;
		}

		return (
			<div className="ui labeled input">
				{video
					? intl.formatMessage({
							id: "MEDIA_TYPE_VIDEO",
							defaultMessage: "Video",
					  })
					: intl.formatMessage({
							id: "MEDIA_TYPE_DISPLAY",
							defaultMessage: "Display",
					  })}
			</div>
		);
	}

	return (<Form.Field inline>
		<label>
			{intl.formatMessage({
				id: "LABEL_CHANNEL",
				defaultMessage: "Channel",
			})}
		</label>
		<Confirm
			open={confirmOpened}
			content={intl.formatMessage({
				id: "BODY_CONFIRM_CHANGE_CHANNEL",
				defaultMessage: "Settings that are specific to the Channel, such as supply, targeting, etc will be cleared",
			})}
			cancelButton={intl.formatMessage({
				id: "BTN_CANCEL",
				defaultMessage: "Cancel",
			})}
			confirmButton={intl.formatMessage({
				id: "BTN_OK",
				defaultMessage: "OK",
			})}
			onCancel={() => setConfirmOpened(false)}
			onConfirm={handleChangeMediaTypeConfirmed}
			id="media_channel_confirm"
			size="mini"
		/>
		{!is_t1_edit ? (
			formControl(flags)
		) : (
			<div className="ui labeled input">
				{value === "video"
					? intl.formatMessage({
							id: "MEDIA_TYPE_VIDEO",
							defaultMessage: "Video",
						})
					: intl.formatMessage({
							id: "MEDIA_TYPE_DISPLAY",
							defaultMessage: "Display",
						})}
			</div>
		)}
	</Form.Field>);
}, (prev, next) => {
	/**
	 * render props that necessary to update component
	 * @param {object} props
	 * @return {string}
	 */
	const rendersProps = props => {
		return JSON.stringify({
			"value": props.value,
			"is_edit": props.is_edit,
			"is_t1_edit": props.is_t1_edit
		})
	}
	return rendersProps(prev) === rendersProps(next);
});
MediaTypePicker.propTypes = {
	"value": PropTypes.string.isRequired,
	"is_edit": PropTypes.bool.isRequired,
	"onChange": PropTypes.func.isRequired
};

const FrequencyCapComponent = ({campaign, onChange, errors, values}) => {
	const intl = useIntl();
	let amountIsDisabled = Boolean(values.frequency_optimization) || values.frequency_type === "no-limit";
	const optimizedIsEnabled = freq_optimization_enabled_by_goal_type(campaign.goal_type && values.goal_type);

	if(values.frequency_optimization && !optimizedIsEnabled) {
		values.frequency_optimization = false;
		amountIsDisabled = Boolean(values.frequency_optimization) || values.frequency_type === "no-limit";
	}

	return (
		<Form.Group inline>
			<Form.Field required>
				<label>
					{intl.formatMessage({
						id: "LABEL_FREQUENCY_CAP",
						defaultMessage: "Frequency Cap",
					})}
				</label>
			</Form.Field>
			<Form.Field>
				<Radio
					label={intl.formatMessage({
						id: "FREQUENCY_CAP_OPTIMIZED",
						defaultMessage: "Optimized",
					})}
					name="frequency_optimization"
					value={1}
					disabled={!optimizedIsEnabled}
					onClick={(e, n) => optimizedIsEnabled? onChange(e, n) : {}}
					checked={Boolean(values.frequency_optimization)}
				/>
				<Radio
					label={intl.formatMessage({
						id: "FREQUENCY_CAP_CUSTOM",
						defaultMessage: "Custom",
					})}
					name="frequency_optimization"
					value={0}
					onClick={onChange}
					checked={!Boolean(values.frequency_optimization)}
				/>
			</Form.Field>
			<Form.Field className="dropdown__125" disabled={Boolean(values.frequency_optimization)}>
				<Dropdown selection
						  fluid
						  name="frequency_type"
						  options={frequency_options(intl)}
						  onChange={onChange}
						  value={values.frequency_type}
				/>
			</Form.Field>
			<Form.Field disabled={amountIsDisabled} error={errors.hasOwnProperty("frequency_amount")}>
				<Input
					type="number"
					name="frequency_amount"
					required={!amountIsDisabled}
					autoComplete="off"
					onBlur={onChange}
					defaultValue={values.frequency_amount}
				/>
				<div className="custom-error" style={{"marginLeft": "0"}}>{errors["frequency_amount"]}</div>
			</Form.Field>
			<Form.Field className="dropdown__125" disabled={amountIsDisabled}>
				<Dropdown selection
						  fluid
						  name="frequency_interval"
						  options={frequency_intervals(intl)}
						  onChange={onChange}
						  value={values.frequency_interval}
				/>
			</Form.Field>
	</Form.Group>);
};

/**
 * generate goal type component following a few rules
 */
const GoalTypeComponent = ({isPG, currency_code, campaign_goal_values, options, onChange, errors, values, loading=false}) => {
	/**
	 * generate goal value component based on selected goal type
	 * @param {string} goal_type
	 * @param {function} onChange
	 * @param {string} value
	 * @param {string} currency_code
	 * @param {number} campaign_goal_values
	 * @return {JSX.Element}
	 */
	const getGoalValueField = (goal_type, onChange, value, currency_code, campaign_goal_values) => {
		let component;
		switch(goal_type) {
			case("roi"):
				component = <GoalTypeValueROI value={value} campaign_goal_values={campaign_goal_values} onChange={onChange} currency={currency_code} />;
			break;

			case("percent"):
				component = <GoalTypeValuePercent value={value} onChange={onChange} />;
			break;

			case("currency"):
			default:
				component = <GoalTypeValueCurrency value={value} onChange={onChange} currency={currency_code} />;
			break;
		}

		return component;
	};

	const intl = useIntl();

	return (
		<Form.Group inline>
			<Form.Field required>
				<label>
					{intl.formatMessage({
						id: "LABEL_GOAL",
						defaultMessage: "Goal",
					})}
				</label>
			</Form.Field>
			<Form.Field disabled={loading} error={errors.hasOwnProperty("goal_type")}>
				<Dropdown
					selection
					disabled={isPG}
					options={options}
					onChange={onChange}
					placeholder={intl.formatMessage({
						id: "HINT_GOAL_TYPE",
						defaultMessage: "Please select type",
					})}
					value={values.goal_type}
					name="goal_type"
				/>
				<div className="custom-error" style={{"marginLeft": "0"}}>{errors["goal_type"]}</div>
			</Form.Field>
			<Form.Field disabled={loading} error={errors.hasOwnProperty("goal_value")}>
				{getGoalValueField(goal_options.filter(x => x.value === values.goal_type)[0].display,
					onChange,
					values.goal_value.toString(),
					currency_code,
					campaign_goal_values)}
				<div className="custom-error" style={{"marginLeft": "0"}}>{errors["goal_value"]}</div>
			</Form.Field>
			<Form.Field disabled={loading}>
				<Select
					fluid
					selection
					disabled={isPG}
					options={goal_media_options(intl)}
					onChange={onChange}
					value={values.bid_price_is_media_only}
					name="bid_price_is_media_only"
				/>
			</Form.Field>
		</Form.Group>
	);
};

const GoalTypeValueROI = ({value, onChange, campaign_goal_values, currency}) => {
	const intl = useIntl();
	let calculated = "--";
	if(isDigit(value) && value > 0) {
		calculated = Number(campaign_goal_values / value).toFixed(2);
	}

	return (
		<>
			<Input
				type="number"
				label={currency}
				name="goal_value"
				required
				defaultValue={value}
				onChange={onChange}
			/>
			{" :1 "}
			{intl.formatMessage(
				{
					id: "BODY_ROI_CALCULATOR",
					defaultMessage: "(equivalent target of {currency} {calculated} CPA)",
				},
				{ currency, calculated }
			)}
		</>
	);
};
GoalTypeValueROI.propTypes = {
	"value": PropTypes.string.isRequired,
	"onChange": PropTypes.func.isRequired,
	"currency": PropTypes.string.isRequired
};

const GoalTypeValueCurrency = ({value, onChange, currency}) => {
	return <><Input
		label={currency}
		type="number"
		name="goal_value"
		required
		defaultValue={value}
		onBlur={onChange}
	/></>
};
GoalTypeValueCurrency.propTypes = {
	"value": PropTypes.string.isRequired,
	"onChange": PropTypes.func.isRequired,
	"currency": PropTypes.string.isRequired
};

const GoalTypeValuePercent = ({value, onChange}) => {
	return <><Input
		label="%"
		type="number"
		labelPosition="right"
		name="goal_value"
		required
		defaultValue={value}
		onBlur={onChange}
	/></>
};
GoalTypeValuePercent.propTypes = {
	"value": PropTypes.string.isRequired,
	"onChange": PropTypes.func.isRequired,
};
