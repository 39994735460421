import { useContext } from "react";
import { useIntl } from "react-intl";
import { Pagination, Table } from "semantic-ui-react";

import { OnlineContext } from "../../../context/online-context";
import InventoryPageContext from "../context";
import Deal from "./Deal";

function DealsGrid() {
  const intl = useIntl();
  const { agency } = useContext(OnlineContext);
  const { state, dispatch, loadDeals } = useContext(InventoryPageContext);
  const items = state.deals.index;

  return (
    <Table className="custom-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            {intl.formatMessage({
              id: "LABEL_DEAL_NAME",
              defaultMessage: "Deal Name",
            })}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {intl.formatMessage({
              id: "LABEL_STATUS",
              defaultMessage: "Status",
            })}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {intl.formatMessage({
              id: "LABEL_DEAL_ID",
              defaultMessage: "Deal ID",
            })}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {intl.formatMessage({
              id: "LABEL_CPM",
              defaultMessage: "CPM",
            })}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {intl.formatMessage({
              id: "LABEL_START_DATE",
              defaultMessage: "Start Date",
            })}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {intl.formatMessage({
              id: "LABEL_END_DATE",
              defaultMessage: "End Date",
            })}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {intl.formatMessage({
              id: "LABEL_SUPPLY_SOURCE",
              defaultMessage: "Supply Source",
            })}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {intl.formatMessage({
              id: "LABEL_DEAL_TYPE",
              defaultMessage: "Deal Type",
            })}
          </Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.length > 0 ? (
          items.map((deal) => <Deal key={deal.id} deal={deal} />)
        ) : (
          <Table.Row>
            <Table.Cell colSpan="9" textAlign="center">
              {intl.formatMessage({
                id: "NO_RESULTS_FOUND",
                defaultMessage: "No results found",
              })}
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.Cell colSpan="12" textAlign="right">
            {state.deals.pager.total_pages > 1 && (
              <Pagination
                size="mini"
                activePage={state.deals.pager.page}
                totalPages={state.deals.pager.total_pages}
                firstItem={null}
                lastItem={null}
                onPageChange={(_, data) => {
                  loadDeals(state, dispatch, data.activePage, agency);
                }}
              />
            )}
          </Table.Cell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
}

export default DealsGrid;
